import PropTypes from 'prop-types'
import { useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { getAppSettings } from '../../../constants/app-settings'
import { useNotificationContext } from '../../../context'
import { query } from '../../../graphql'
import { classNames, isDefined } from '../../../helpers'
import { useCreateCareerNavigation } from '../../../hooks/use-create-career-navigation'
import Loader from '../../activity-indicator'
import Button from '../../tailwind/Button'
import CustomizationTestBattery from './CustomizationTestBattery'
import HardnessOfSelection from './HardnessOfSelection'
import MustCriteriaSelection from './MustCriteriaSelection'

const I18N_PREFIX = 'create_career.adjustment.'

const TabNavigation = ({ tabs, activeTab, onTabClick }) => {
  return (
    <nav aria-label='Tabs' className='isolate flex divide-x divide-gray-200'>
      {tabs.map((tab) => {
        const isActive = activeTab === tab.id
        return (
          <div
            key={tab.id}
            onClick={() => onTabClick(tab.id)}
            aria-current={isActive ? 'page' : undefined}
            className={classNames(
              isActive ? 'text-gray-900' : 'text-gray-700 hover:text-gray-800',
              'relative min-w-0 flex-1 cursor-pointer overflow-hidden bg-slate-50 px-4 py-4 text-center text-sm font-medium hover:bg-slate-100 focus:z-10'
            )}
          >
            <span>{tab.name}</span>
            <span
              aria-hidden='true'
              className={classNames(
                isActive ? 'bg-blue-500' : 'bg-transparent',
                'absolute inset-x-0 bottom-0 h-0.5'
              )}
            />
          </div>
        )
      })}
    </nav>
  )
}

TabNavigation.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired
}

const TabDropdown = ({ tabs, activeTab, onTabChange }) => {
  return (
    <select
      id='tabs'
      name='tabs'
      value={activeTab}
      onChange={(e) => onTabChange(e.target.value)}
      className='block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500'
    >
      {tabs.map((tab) => (
        <option key={tab.id} value={tab.id}>
          {tab.name}
        </option>
      ))}
    </select>
  )
}

TabDropdown.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired
}

const Adjustment = ({ career, careerMutation, refetchCareer }) => {
  const { t } = useTranslation()
  const { alert } = useNotificationContext()

  const tabs = [
    {
      id: 'CUSTOMIZE_TEST_BATTERY',
      name: t(I18N_PREFIX + 'tab_title_customize_testbattery')
    },
    {
      id: 'SELECTION_HARDNESS',
      name: t(I18N_PREFIX + 'tab_title_selection_hardness')
    },
    {
      id: 'SELECTION_MUST_CRITERIA',
      name: t(I18N_PREFIX + 'tab_title_selection_must_criteria')
    }
  ]

  const [activeTab, setActiveTab] = useState(tabs[0].id)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)

  const { isLoadingBack, handleBackNavigation, handleNextNavigation } =
    useCreateCareerNavigation({
      career,
      careerMutation
    })

  const parsedCareerAnalyse = JSON.parse(career.career_analyse)

  const abort = () => {
    alert(
      t(I18N_PREFIX + 'modal_cancel_title'),
      [
        t(I18N_PREFIX + 'modal_cancel_text_0'),
        t(I18N_PREFIX + 'modal_cancel_text_1')
      ],
      [
        {
          onPress: async () => {
            parsedCareerAnalyse.override_defaults = null
            const meta = JSON.parse(career.customer_dashboard_meta)

            careerMutation.mutate(
              {
                input: {
                  id: career.id,
                  career_analyse: JSON.stringify(parsedCareerAnalyse),
                  customer_dashboard_meta: JSON.stringify({
                    ...meta,
                    expert_mode_used: false
                  })
                }
              },
              {
                onSuccess: () =>
                  handleBackNavigation({
                    backStatus: 'WAIT_CONFIRM_TESTBATTERY'
                  })
              }
            )
          },
          text: t(I18N_PREFIX + 'modal_cancel_action'),
          style: 'destructive'
        },
        {
          text: t(I18N_PREFIX + 'modal_cancel_cancel'),
          style: 'cancel'
        }
      ],
      <ExclamationTriangleIcon className='mb-2 h-12 w-12 text-red-500' />,
      null,
      true
    )
  }

  const complete = async () => {
    setIsLoadingComplete(true)

    try {
      const selectedChallenges =
        parsedCareerAnalyse.override_defaults.selected_challenges

      const app_settings = getAppSettings(
        career.app_settings,
        selectedChallenges
      )

      await careerMutation.mutate(
        {
          input: { id: career.id, app_settings }
        },
        {
          onSuccess: async () => {
            await query({
              query: 'synthesizeAnalyze',
              variables: { career_id: career.id }
            })
            handleNextNavigation({ nextStatus: 'WAIT_ACTIVE' })
          }
        }
      )
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoadingComplete(false)
    }
  }

  const renderTabContent = () => {
    const props = { career, careerMutation, refetchCareer }

    switch (activeTab) {
      case 'CUSTOMIZE_TEST_BATTERY':
        return <CustomizationTestBattery {...props} />
      case 'SELECTION_HARDNESS':
        return <HardnessOfSelection {...props} />
      case 'SELECTION_MUST_CRITERIA':
        return <MustCriteriaSelection {...props} />
      default:
        return null
    }
  }

  if (!isDefined(parsedCareerAnalyse.override_defaults)) {
    return <Loader />
  }

  return (
    <div className='mx-auto max-w-4xl'>
      <div className='mb-10 mt-10 md:flex md:items-center md:justify-between'>
        <div className='min-w-0 flex-1'>
          <h2 className='text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
            Spezial-Modus
          </h2>
        </div>
        <div className='mt-4 flex md:ml-4 md:mt-0'>
          <Button.SecondaryXL
            text={isLoadingBack ? '...' : t(I18N_PREFIX + 'abort')}
            onClick={abort}
          ></Button.SecondaryXL>
          <Button.PrimaryXL
            text={isLoadingComplete ? '...' : t(I18N_PREFIX + 'complete')}
            onClick={complete}
          />
        </div>
      </div>

      <div className='mt-4'>
        <div className='md:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            {t(I18N_PREFIX + 'select_a_tab')}
          </label>
          <TabDropdown
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </div>
        <div className='hidden md:block'>
          <TabNavigation
            tabs={tabs}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
        </div>
      </div>
      <div className='mt-8'>{renderTabContent()}</div>
    </div>
  )
}

Adjustment.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired,
  refetchCareer: PropTypes.func.isRequired
}

export default Adjustment
