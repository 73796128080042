import { flatten, uniq } from 'lodash'

export const cultureFitScores = {
  HEROS_JOURNEY_score_coopcomp: 'HEROS_JOURNEY_score_coopcomp',
  HEROS_JOURNEY_score_orderchaos: 'HEROS_JOURNEY_score_orderchaos'
}

export const getScoreObject = (cultural_fit) => {
  return {
    HEROS_JOURNEY_score_coopcomp: cultural_fit.HEROS_JOURNEY_score_coopcomp,
    HEROS_JOURNEY_score_orderchaos: cultural_fit.HEROS_JOURNEY_score_orderchaos
  }
}

/**
 * standard = true // should always be included
 * include = true // is the best possible testbatttery
 * recommended = true // some challenges are recommended
 * optional = true // some challenges are optional inside the success screen
 *
 * @param {*} scores
 * @returns configuration object for the testbattery overview
 */
export const testBatteryConfig = ({
  ANALOGICAL_REASONING_score,
  NUMERACY_score,
  EMOTIONS_GAME_score,
  NBACK_score,
  PROBLEM_SOLVING_score,
  DIGIT_SPAN_score,
  DELEGATION_score_responsibility
}) => ({
  BIG_FIVE: { standard: true, include: true, recommended: false },
  HEROS_JOURNEY: { standard: true, include: true, recommended: false },
  FLANKER: {
    standard: true,
    include: true,
    recommended: false // explicitly false, even though it is a game of skill / is already standard
  },
  TOWER_OF_LONDON: {
    standard: true,
    include: true,
    recommended: false // explicitly false, even though it is a game of skill / is already standard
  },
  ANALOGICAL_REASONING: {
    include: ANALOGICAL_REASONING_score.optimal >= 25,
    recommended: ANALOGICAL_REASONING_score.optimal >= 25
  },
  NUMERACY: {
    include: NUMERACY_score.optimal >= 25,
    recommended: NUMERACY_score.optimal >= 25
  },
  EMOTIONS_GAME: {
    include: EMOTIONS_GAME_score.optimal >= 50,
    recommended: EMOTIONS_GAME_score.optimal >= 50
  },
  NBACK: {
    include:
      NBACK_score.optimal >= 50 ||
      (NBACK_score.optimal >= 25 && PROBLEM_SOLVING_score.optimal >= 50),
    recommended:
      NBACK_score.optimal >= 50 ||
      (NBACK_score.optimal >= 25 && PROBLEM_SOLVING_score.optimal >= 50)
  },
  DIGIT_SPAN: {
    include:
      DIGIT_SPAN_score.optimal >= 50 ||
      (DIGIT_SPAN_score.optimal >= 25 && PROBLEM_SOLVING_score.optimal >= 50),
    recommended:
      DIGIT_SPAN_score.optimal >= 50 ||
      (DIGIT_SPAN_score.optimal >= 25 && PROBLEM_SOLVING_score.optimal >= 50)
  },
  DELEGATION: {
    include: DELEGATION_score_responsibility.optimal >= 50,
    recommended: DELEGATION_score_responsibility.optimal >= 50
  },
  RIASEC_IMAGES: { include: false, optional: true }
})

export const getDifficultyOptions = () => [
  {
    value: -1,
    label: 'create_career.hardness_of_selection.options.1',
    difficulty: 'EASY'
  },
  {
    value: 0,
    label: 'create_career.hardness_of_selection.options.2',
    difficulty: 'NORMAL'
  },
  {
    value: 1,
    label: 'create_career.hardness_of_selection.options.3',
    difficulty: 'HARD'
  }
]

/**
 * function to map challenges to covered dimensions
 *
 * @param {*} challenges / [ { exam_id: 'BIG_FIVE' }, ... ]
 * @param {*} dimensions / Object.values(system.dimensions.data)
 * @returns string array / [ 'ANALOGICAL_REASONING_score', ... ]
 */
export const mapChallengesToCoveredDimensions = ({
  challenges,
  dimensions
}) => {
  const getAllDimensions = ({ exam_id }) => {
    return dimensions
      .filter((dimension) => dimension.measured_by.includes(exam_id))
      .map(({ key }) => key)
  }

  return uniq(flatten(challenges.map(getAllDimensions)))
}
