import { Hub } from 'aws-amplify'
import { useEffect } from 'react'

const UrlLogoutListener = () => {
  useEffect(() => {
    const checkLogoutUrl = () => {
      if (window.location.pathname === '/logout') {
        Hub.dispatch('auth', { event: 'signOut' })
      }
    }

    // at the moment its enough to check immediately
    // in case user lands on /logout directly
    checkLogoutUrl()

    // window.addEventListener('popstate', checkLogoutUrl)
    // return () => window.removeEventListener('popstate', checkLogoutUrl)
  }, [])

  return null
}

export default UrlLogoutListener
