import { useQuery, useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Loader } from './components'
import Layout from './components/layout'
import CompleteSignup from './pages/complete-signup'
import Error404 from './pages/error-404'
import { routes } from './routes/index'

import { checkForUserBranch, notifyBugsnag, selectSystemData } from './helpers'
import { trackEvent } from './helpers/analytics'
import { localStorage } from './helpers/local-storage'

import AccessDenied from './components/access-denied'
import ReleaseNotes from './components/release-notes'
import StartupHandler from './components/startup-handler'
import Trial from './components/trial/trial'
import trackEvents from './constants/track-events'
import { DataContext, GeneralContext } from './context'
import { query } from './graphql'
import { useMount } from './hooks/use-mount'
import Login from './pages/login'
import SignMeWithEmailPassword from './pages/sign-me-with-email-password'
import TryoutFunnel from './pages/tryout-funnel'
// import Checkout from './pages/checkout'
import TryoutDe from './pages/tryout-de'
import TryoutEn from './pages/tryout-en'

function App({ language, cognitoUser, restartDashboard }) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [accessDenied, setAccessDenied] = useState(false)

  useMount(() => {
    checkForUserBranch()
    trackEvent(trackEvents.LAUNCH)
  })

  const username = useMemo(() => {
    if (!cognitoUser) return null

    return cognitoUser.username
  }, [cognitoUser])

  const cognitoGroups = useMemo(() => {
    if (!cognitoUser) return []

    const { payload } = cognitoUser.signInUserSession.accessToken
    const groups = [...(payload['cognito:groups'] || [])]

    return groups
  }, [cognitoUser])

  const isAdmin = useMemo(
    () => cognitoGroups.includes('__admin'),
    [cognitoGroups]
  )

  const partner_id = useMemo(() => {
    const active_partner_id_from_groups = cognitoGroups.find((g) =>
      g.includes('__active_partner_id')
    )

    if (!active_partner_id_from_groups) return

    return (
      localStorage.getItem('admin') ||
      active_partner_id_from_groups.split(':').pop()
    )
  }, [cognitoGroups])

  const active_space_id = useMemo(() => {
    const active_space_id_from_groups = cognitoGroups.find((g) =>
      g.includes('__active_space_id')
    )

    if (!active_space_id_from_groups) return

    return active_space_id_from_groups.split(':').pop()
  }, [cognitoGroups])

  const { data: activeSpace, refetch: refetchActiveSpace } = useQuery({
    queryKey: ['active_space', active_space_id],
    queryFn: () => {
      return query({ query: 'getSpace', variables: { id: active_space_id } })
    },
    enabled: !!active_space_id,
    refetchOnWindowFocus: false
  })

  const { data: user } = useQuery({
    queryKey: ['user', username],
    queryFn: () => {
      return query({ query: 'getUser', variables: { id: username } })
    },
    enabled: !!username
  })

  const { data: spacesByOwner, refetch: refetchSpacesByOwner } = useQuery({
    queryKey: ['spaces-by-owner', username],
    queryFn: async () => {
      // query spaces by owner
      // query corresponding partners for employees
      const spaces = await query({
        query: 'spacesByOwner',
        variables: { owner: username }
      })

      const partners = await Promise.all(
        spaces.map((s) =>
          query({
            query: 'getTrimmedPartner',
            variables: { id: s.partner_id },
            options: { origin: 'getPartner' }
          })
        )
      )

      return spaces.map((s) => ({
        ...s,
        partner: partners.find(({ id }) => id === s.partner_id)
      }))
    },
    refetchOnWindowFocus: false,
    enabled: !!username
  })

  const { data: partner, refetch: refetchPartner } = useQuery({
    queryKey: ['partner', partner_id],
    queryFn: async () => {
      const partner = await query({
        query: 'getPartner',
        variables: { id: partner_id }
      })

      if (!partner) {
        const message = `partner not found / dashboard restart - username: ${cognitoUser.username} / partner_id: ${partner_id}`
        notifyBugsnag(new Error(message))

        await queryClient.invalidateQueries()
        localStorage.clear()
        window.location.assign(window.location.origin)

        return
      }

      return partner
    },
    enabled: !!partner_id
  })

  const { data: emailEndpoint, refetch: refetchEmailEndpoint } = useQuery({
    queryKey: ['user-endpoints', username],
    queryFn: async () => {
      let response = null

      try {
        response = await query({
          query: 'pinpointControl',
          variables: { action: 'getUserEndpoints', user_id: username }
        })
      } catch (err) {
        if (err.message.toLowerCase().includes('resource not found')) {
          response = 'RESOURCE_NOT_FOUND'
        }
      }

      return response
    },
    select: (response) => {
      if (response === 'RESOURCE_NOT_FOUND') return 'RESOURCE_NOT_FOUND'

      return JSON.parse(
        response
      ).body.getUserEndpoints.res.EndpointsResponse.Item.find(
        ({ Id }) => Id === `email_${username}`
      )
    },
    enabled: !!username
  })

  const { data: system } = useQuery({
    queryKey: ['system'],
    queryFn: () => {
      return Promise.all(
        [
          'CURRENT_CHALLENGE_CONFIG_ID',
          'MAIL_TEMPLATES',
          'CHALLENGES',
          'CATEGORIES',
          'DIMENSIONS',
          'DIMENSIONS_PDF_REPORTS',
          'ANNOUNCEMENT'
        ].map((id) => query({ query: 'getSystem', variables: { id } }))
      )
    },
    select: (items) => {
      try {
        return selectSystemData(items)
      } catch (err) {
        notifyBugsnag(err)
      }
    },
    enabled: !!username
  })

  const { data: payments } = useQuery({
    queryKey: ['payment', partner_id],
    queryFn: async () => {
      const response = await query({
        query: 'paymentByPartnerIdByCreatedAt',
        variables: { partner_id }
      })

      if (!response.length) {
        setAccessDenied(true)

        const message = `no payment found / access denied - username: ${cognitoUser.username} / partner_id: ${partner_id}`
        notifyBugsnag(new Error(message))
        return []
      }

      return response
    },
    enabled: !!partner_id
  })

  if (accessDenied) {
    return <AccessDenied />
  }

  // show authenticator if !cognitoUser
  const isLoading = cognitoUser
    ? !activeSpace ||
      !system ||
      !partner ||
      !payments?.length ||
      !emailEndpoint ||
      !spacesByOwner ||
      !user
    : false

  if (isLoading) {
    return <Loader loadingText={t('app.dashboard_loading_text')} />
  }

  if (!cognitoUser) {
    return (
      <GeneralContext language={language}>
        <BrowserRouter>
          <Routes>
            <Route path='/ausprobieren' element={<TryoutDe />} />
            <Route path='/de/ausprobieren' element={<TryoutDe />} />
            <Route path='/en/try' element={<TryoutEn />} />
            <Route path='/login' element={<Login />} />
            <Route path='/ausprobieren/:id' element={<TryoutFunnel />} />
            <Route
              path='/signmewithemailpassword'
              element={<SignMeWithEmailPassword />}
            />
            {/* <Route path='/checkout' element={<Checkout />} /> */}
            <Route path='*' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </GeneralContext>
    )
  }

  return (
    <GeneralContext language={language}>
      <BrowserRouter>
        <DataContext
          system={system}
          restartDashboard={restartDashboard}
          cognitoUser={{ ...cognitoUser, groups: cognitoGroups, partner_id }}
          active_space={activeSpace}
          refetch_active_space={refetchActiveSpace}
          isAdmin={isAdmin}
          partner={partner}
          refetchPartner={refetchPartner}
          payments={payments}
          emailEndpoint={emailEndpoint}
          refetchEmailEndpoint={refetchEmailEndpoint}
          spacesByOwner={spacesByOwner}
          refetchSpacesByOwner={refetchSpacesByOwner}
          user={user}
        >
          <StartupHandler />
          <Routes>
            {/* <Route path='/ausprobieren' element={<Tryout />} /> */}
            <Route path='/' element={<Navigate to='/talents' replace />} />
            <Route path='/login' element={<Navigate to='/talents' replace />} />

            {routes.map(({ path, Component, useLayout }) => (
              <Route
                key={path}
                path={path}
                element={
                  useLayout ? (
                    <Layout>
                      <Trial />
                      <Component />
                      <ReleaseNotes startup asPopup />
                      {/* <ProductTour /> */}
                    </Layout>
                  ) : (
                    <Component />
                  )
                }
              />
            ))}

            <Route path='/complete-signup' element={<CompleteSignup />} />

            <Route
              path='/ausprobieren'
              element={<Navigate replace to='/careers' />}
            />
            <Route path='*' element={<Error404 />} />
          </Routes>
        </DataContext>
      </BrowserRouter>
    </GeneralContext>
  )
}

App.propTypes = {
  language: PropTypes.string.isRequired,
  cognitoUser: PropTypes.object,
  restartDashboard: PropTypes.func.isRequired
}

App.defaultProps = {
  cognitoUser: null
}

export default App
