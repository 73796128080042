import Career from '../pages/career'
import Careers from '../pages/careers'
import ConfirmIntegration from '../pages/confirm-integration'
import CreateCareer from '../pages/create-career'
import ReleaseNotesPage from '../pages/release-notes'
import Settings from '../pages/settings'
import Support from '../pages/support'
// import Payment from '../../pages/payment'
import CreateTeamAnalyse from '../pages/create-team-analyse'
import RecoverCareers from '../pages/recover-careers'
import RecoverTalents from '../pages/recover-talents'
import Talent from '../pages/talent'
import Talents from '../pages/talents'
import TeamAnalyse from '../pages/team-analyse'
import TeamAnalyses from '../pages/team-analyses'
import TestLibrary from '../pages/test-library'
// import Upgrade from '../pages/upgrade'
// #import Checkout from '../pages/checkout'
// import FAQ from '../../pages/faq'

export const routes = [
  { path: '/careers', Component: Careers, useLayout: true },
  { path: '/career/:id', Component: Career, useLayout: true },
  { path: '/career/new', Component: CreateCareer, useLayout: true },
  { path: '/career/new/:id', Component: CreateCareer, useLayout: true },
  { path: '/talents', Component: Talents, useLayout: true },
  { path: '/talent/:id', Component: Talent, useLayout: true },
  { path: '/team-analyses', Component: TeamAnalyses, useLayout: true },
  {
    path: '/team-analyse/new',
    Component: CreateTeamAnalyse,
    useLayout: true
  },
  {
    path: '/team-analyse/new/:id',
    Component: CreateTeamAnalyse,
    useLayout: true
  },
  { path: '/team-analyse/:id', Component: TeamAnalyse, useLayout: true },
  // { path: '/payment', Component: Payment, useLayout: true },
  { path: '/settings', Component: Settings, useLayout: true },
  { path: '/support', Component: Support, useLayout: true },
  { path: '/release-notes', Component: ReleaseNotesPage, useLayout: true },
  { path: '/recover/talents', Component: RecoverTalents, useLayout: true },
  { path: '/recover/careers', Component: RecoverCareers, useLayout: true },
  { path: '/test-library', Component: TestLibrary, useLayout: true },
  {
    path: '/confirm/integration',
    Component: ConfirmIntegration,
    useLayout: false
  }
  // { path: '/upgrade', Component: Upgrade, useLayout: true },
  // { path: '/checkout', Component: Checkout, useLayout: false },
]
