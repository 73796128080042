import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { Amplify, Hub } from 'aws-amplify'
import {
  get as getItem,
  clear as idbClear,
  del as removeItem,
  set as setItem
} from 'idb-keyval'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import './App.css'

import App from './App'
import './i18n'
import './index.css'
// import i18n (needs to be bundled ;))
import awsconfig from './aws-exports'
import { Loader } from './components'
import SubdomainInfo from './components/SubdomainInfo'
import UrlLogoutListener from './components/UrlLogoutListener'
import AccessDenied from './components/access-denied'
import Maintenance from './components/maintenance'
import {
  BUILD_ENV,
  NODE_ENV,
  createErrorLog,
  getSubdomain,
  initializeFirebase,
  isNetworkError
} from './helpers'
import { getPartner } from './helpers/partner'
import { getUser } from './helpers/user'
import { useCognito } from './hooks/use-cognito'
import { useI18n } from './hooks/use-i18n'
import { useRestartDashboard } from './hooks/use-restart-dashboard'
import { useSystemStatus } from './hooks/use-system-status'

const { version } = require('../package.json')

initializeFirebase()

Amplify.configure({
  ...awsconfig,
  Analytics: { autoSessionRecord: false }
})

Bugsnag.start({
  apiKey: 'c8939f4d1cc8053876ee36682765eeff',
  plugins: [new BugsnagPluginReact()],
  releaseStage: BUILD_ENV,
  appVersion: version,
  onError: (event) => {
    if (window.location.pathname === '/logout') {
      Hub.dispatch('auth', { event: 'signOut' })
      return false
    }

    if (NODE_ENV === 'development') return false

    // event.errors is an array of one or more Error objects.
    // The first item in the list represents the thrown object.
    // Each subsequent item represents the error that was
    // set as the cause of the preceding one.

    // An Error object contains the following information:
    // errorMessage /	String / The message string from the error
    // ...

    if (isNetworkError({ message: event.errors[0].errorMessage })) {
      return false
    }

    if (event.unhandled) idbClear()

    const user_id = getUser().username
    const partner = getPartner()

    const { aws_user_pools_id, aws_cognito_region } = awsconfig
    const cognito_url = user_id
      ? 'https://eu-central-1.console.aws.amazon.com/cognito/v2/idp/user-pools/' +
        `${aws_user_pools_id}/user-management/users/details/${user_id}?region=${aws_cognito_region}`
      : null

    event.context = `version ${version}`
    event.addMetadata('meta', {
      version,
      build_env: BUILD_ENV.toUpperCase(),
      partner_id: partner.id,
      partner_name: partner.name,
      user_id,
      cognito_url
    })

    createErrorLog({ event })
  }
})

const originalConsoleError = console.error

const customConsoleError = (...args) => {
  originalConsoleError.apply(console, args)

  // everything else gets handled by bugsnag on error
  Bugsnag.notify(new Error(args.join(' ')))
}

console.error = customConsoleError

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const FOURTEEN_DAYS = 1000 * 60 * 60 * 24 * 14

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { gcTime: FOURTEEN_DAYS },
    mutations: { gcTime: FOURTEEN_DAYS }
  }
})

const asyncStoragePersister = createAsyncStoragePersister({
  storage: { getItem, setItem, removeItem }
})

const container = document.getElementById('root')

const root = createRoot(container) // createRoot(container!) if you use TypeScript

const Root = () => {
  const { language } = useI18n()
  const { status: systemStatus } = useSystemStatus()

  const { state: restartDashboardState, restartDashboard } =
    useRestartDashboard({ queryClient })

  const { state: cognitoStatus, cognitoUser } = useCognito({
    restartDashboard
  })

  const subdomain = getSubdomain()

  /**
   * REMOVE AFTER A WHILE
   */
  if (subdomain && subdomain === 'preview') {
    return <SubdomainInfo />
  }

  if (systemStatus === 'INITIAL' || cognitoStatus === 'INITIAL') {
    return <Loader />
  }

  if (cognitoStatus === 'ACCESS_DENIED') {
    return <AccessDenied />
  }

  if (systemStatus === 'OFFLINE') {
    return <Maintenance prefix='offline.' support />
  }

  if (systemStatus === 'MAINTENANCE_WORK') {
    return <Maintenance prefix='maintenance_work.' />
  }

  if (restartDashboardState === 'RESTART') {
    return (
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{
          persister: asyncStoragePersister,
          buster: `${version}`
        }}
      >
        <ErrorBoundary
          FallbackComponent={() => (
            <Maintenance prefix='error_unhandled.' homepage support />
          )}
        >
          <Loader />
        </ErrorBoundary>
      </PersistQueryClientProvider>
    )
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister: asyncStoragePersister,
        buster: `${version}`
      }}
    >
      <ErrorBoundary
        FallbackComponent={() => (
          <Maintenance prefix='error_unhandled.' homepage support />
        )}
      >
        <App {...{ language, cognitoUser, restartDashboard }} />
        <ReactQueryDevtools initialIsOpen={false} />
      </ErrorBoundary>
    </PersistQueryClientProvider>
  )
}

root.render(
  <React.StrictMode>
    <Root />
    <UrlLogoutListener />
  </React.StrictMode>
)

// # Strict Mode
//
// In React 18, the strict mode simulates mounting, unmounting,
// and remounting the component with a previous state.
//
// For instance, when a user tabs away from a screen and back,
// React should be able to display the previous screen quickly.
// To accomplish this, React would unmount and remount trees using
// the previous component state as before.
//
// This feature improves React apps’ performance but requires components
// are resilient to the effect of being mounted and unmounted multiple times.
