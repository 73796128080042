import { ClockIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import { useState } from 'react'
import { useLanguageContext } from '../../../context'
import ChallengeInfoModal from '../../challenge-info-modal'
import Badge from '../../tailwind/Badge'
import Button from '../../tailwind/Button'

const ChallengeCard = ({
  className = '',
  challenge,
  recommended,
  selected = false,
  onClick = undefined
}) => {
  const { t } = useTranslation()
  const { language } = useLanguageContext()

  const [openInfoModal, setOpenInfoModal] = useState(false)

  return (
    <>
      <div
        onClick={onClick}
        className={clsx(
          'group relative flex flex-col rounded-xl border border-slate-200 bg-slate-50 p-6',
          onClick && selected && '!border-indigo-600 ring-1 ring-indigo-600',
          onClick &&
            'cursor-pointer hover:border-indigo-600 hover:ring-1 hover:ring-indigo-600',
          className
        )}
      >
        {onClick && selected && (
          <CheckCircleIcon className='absolute right-4 top-4 size-6 text-indigo-600' />
        )}

        {onClick && !selected && (
          <PlusCircleIcon className='invisible absolute right-4 top-4 size-6 text-indigo-600 group-hover:visible' />
        )}

        <div className='flex items-center'>
          <img
            src={challenge.card.image}
            className='hidden h-14 w-14 rounded-full object-contain p-1 sm:inline-block'
            style={{ backgroundColor: challenge.backgroundColor }}
          />
          <div className='sm:ml-4'>
            <span className='text-2xl font-bold text-gray-900'>
              {challenge.title[language]}
            </span>
            <div className='mt-0.5 flex flex-wrap gap-1'>
              <Badge
                color='indigo'
                text={challenge.dimensionSynonym[language]}
              />
              {challenge.standard && (
                <Badge
                  color='purple'
                  text={t(
                    'create_career.challenge_card.default_challenge_label'
                  )}
                />
              )}
              {challenge.recommended && (
                <Badge
                  color='green'
                  text={t(
                    'create_career.challenge_card.recommended_by_aivy_label'
                  )}
                />
              )}
            </div>
          </div>
        </div>
        <div className='mt-4'>
          <span className='block text-sm font-semibold text-gray-900'>
            {t('create_career.challenge_card.measuring')}
          </span>
          <span className='mt-0.5 block max-w-xl text-sm text-gray-900'>
            {challenge.measuring[language]}
          </span>
        </div>
        <div className='mt-4 flex items-center'>
          <div className='ml-auto flex items-center'>
            <ClockIcon className='h-6 w-6 text-gray-900' />
            <span className='ml-1 whitespace-nowrap text-sm font-medium text-gray-900'>
              {`${challenge.timeToComplete / 60000} ${t('global.minutes')}`}
            </span>
          </div>
          <div className='ml-4' />
          <Button.SecondaryLG
            text={t('create_career.challenge_card.details_action')}
            onClick={(event) => {
              event.stopPropagation()
              setOpenInfoModal(true)
            }}
          />
        </div>
      </div>
      <ChallengeInfoModal
        open={openInfoModal}
        setOpen={setOpenInfoModal}
        challenge={challenge}
      />
    </>
  )
}

ChallengeCard.propTypes = {
  className: PropTypes.string,
  challenge: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func
}

export default ChallengeCard
