/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'

import { useLanguageContext } from '../../context'
import { startStrWithCapital } from '../../helpers'

const SlideOverDimensionDescription = ({ open, setOpen }) => {
  const { language } = useLanguageContext()

  const { property, intro, scaleEndpoints } = open || {}

  return (
    <Transition show={!!open} as={Fragment}>
      <Dialog className='relative z-10' onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <TransitionChild
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel className='pointer-events-auto w-screen max-w-md'>
                  {open ? (
                    <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                      <div className='px-4 sm:px-6'>
                        <div className='flex items-start justify-between'>
                          <DialogTitle className='text-base font-semibold leading-6 text-gray-900'>
                            {property[language]}
                          </DialogTitle>
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                              onClick={() => setOpen(false)}
                            >
                              <span className='absolute -inset-2.5' />
                              <span className='sr-only'>Close panel</span>
                              <XMarkIcon
                                className='h-6 w-6'
                                aria-hidden='true'
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                        <span className='text-base text-gray-900'>
                          {intro[language]}
                        </span>
                        {scaleEndpoints.description ? (
                          <div className='mt-8 text-sm text-gray-900'>
                            {scaleEndpoints.description[language]}
                          </div>
                        ) : (
                          <>
                            {scaleEndpoints.left && (
                              <div className='mt-8'>
                                <span className='block text-sm font-semibold text-gray-900'>
                                  {startStrWithCapital(
                                    scaleEndpoints.left.title[language]
                                  )}
                                </span>
                                <span className='mt-2 text-sm text-gray-900'>
                                  {scaleEndpoints.left.description_long
                                    ? scaleEndpoints.left.description_long[
                                        language
                                      ]
                                    : scaleEndpoints.left.description[language]}
                                </span>
                              </div>
                            )}
                            {scaleEndpoints.right && (
                              <div className='mt-8'>
                                <span className='block text-sm font-semibold text-gray-900'>
                                  {startStrWithCapital(
                                    scaleEndpoints.right.title[language]
                                  )}
                                </span>
                                <span className='mt-2 text-sm text-gray-900'>
                                  {scaleEndpoints.right.description_long
                                    ? scaleEndpoints.right.description_long[
                                        language
                                      ]
                                    : scaleEndpoints.right.description[
                                        language
                                      ]}
                                </span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default SlideOverDimensionDescription
