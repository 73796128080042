import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import Input from '../input'
import SelectMenu from '../select-menu'
// import SelectRiasecSearchInput from '../select-riasec-search-input'
import { useState } from 'react'
import { CREATE_CAREER, UPDATE_STATUS } from '../../constants/career-history'
import { DE, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import {
  usePartnerContext,
  usePaymentContext,
  useProductTourContext
} from '../../context'
import { mutation, query } from '../../graphql'
import { sendSlackMessage } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { getCreateCareerInput } from '../../helpers/career'
import { useCreateLog } from '../../hooks/use-create-log'
import { useMount } from '../../hooks/use-mount'
import Button from '../tailwind/Button'
import ExternalIdAlert from './pieces/ExternalIdAlert'

const I18N_LOCATION = 'components.careers.create_career_dialog.'

const CreateNewCareer = ({ career, careerMutation, isDuplicate, careers }) => {
  const { t } = useTranslation()
  const { id: career_id_param } = useParams()
  const { is_trial } = usePaymentContext()

  const { partner } = usePartnerContext()

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { createCareerHistoryLog } = useCreateLog()

  const [isLoadingNext, setIsLoadingNext] = useState(false)

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      title: career.title || '',
      category: career.category || '-1',
      language: career.language || DE,
      external_custom_id: career.external_custom_id || ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required(
        t(I18N_LOCATION + 'input_career_title_validation')
      ),
      external_custom_id: Yup.string().matches(
        /^[a-zA-Z0-9-]+$/,
        t(I18N_LOCATION + 'input_external_custom_id_validation')
      )
    })
  })

  const handleSubmit = async ({
    title,
    category,
    language,
    external_custom_id
  }) => {
    formik.setSubmitting(true)

    if (is_trial) {
      queryClient.refetchQueries({
        queryKey: ['trial-data', partner.id],
        type: 'active'
      })
    }

    if (!career_id_param) {
      // create a new career
      try {
        const new_career = await mutation({
          mutation: 'createCareer',
          input: {
            external_custom_id: external_custom_id || undefined,
            diagnostic_version: 2,
            ...getCreateCareerInput({
              title,
              category,
              language,
              partner_id: partner.id,
              status: 'WAIT_CULTURAL_FIT'
            })
          }
        })

        await createCareerHistoryLog({
          career_id: new_career.id,
          context: CREATE_CAREER,
          input: { status: 'WAIT_CULTURAL_FIT' }
        })

        navigate(`/career/new/${new_career.id}`, { replace: true })
      } catch (err) {
        formik.setSubmitting(false)
      }
    } else {
      // update the career or activate if is duplicate
      if (isDuplicate) {
        setIsLoadingNext(true)

        await createCareerHistoryLog({
          career_id: career.id,
          context: UPDATE_STATUS,
          input: { status: 'ACTIVE' }
        })

        await mutation({
          mutation: 'updateCareer',
          input: {
            id: career.id,
            title,
            // category,
            language,
            // if external_custom_id is an empty string pass null
            // DynamoDB treats null as a removal operation for that attribute
            external_custom_id: external_custom_id || null
          }
        })

        const response = await query({
          query: 'careerControl',
          variables: { action: 'activateCareer', career_id: career.id }
        })

        const { name: partner_name } = partner
        const { id: activated_career_id, title: career_title } =
          JSON.parse(response).activateCareer.res

        if (!activated_career_id.includes('sample')) {
          trackEvent(trackEvents.FINISH_CREATE_CAREER)

          if (is_trial) {
            queryClient.refetchQueries({
              queryKey: ['trial-data', partner.id],
              type: 'active'
            })
          }

          sendSlackMessage(
            'kunden-controlling',
            `Die Stelle *${career_title}* (${activated_career_id}) von _${partner_name}_ ${
              is_trial ? '(TRIAL)' : ''
            } ist nun aktiv.`
          )
        }

        // invalidate cache because career_id has changed
        if (activated_career_id !== career.id) queryClient.removeQueries()

        setIsLoadingNext(false)
        navigate(`/career/${activated_career_id}`, { replace: true })

        return
      }

      careerMutation.mutate(
        {
          input: {
            id: career.id,
            title,
            category,
            language,
            // if external_custom_id is an empty string pass null
            // DynamoDB treats null as a removal operation for that attribute
            external_custom_id: external_custom_id || null,
            status: 'WAIT_CULTURAL_FIT'
          }
        },
        {
          onSuccess: (data) => {
            createCareerHistoryLog({
              career_id: data.id,
              context: UPDATE_STATUS,
              input: { status: 'WAIT_CULTURAL_FIT' }
            })
          },
          onError: () => formik.setSubmitting(false)
        }
      )
    }
  }

  const foundCareerWithExternalCustomID = careers.find(
    ({ status, external_custom_id }) => {
      return (
        status === 'ACTIVE' &&
        external_custom_id === formik.values.external_custom_id
      )
    }
  )

  return (
    <div className='mx-auto max-w-4xl'>
      <span className='text-4xl font-extrabold tracking-tight text-blue-600'>
        {t(I18N_LOCATION + 'dialog_title')}
      </span>
      <p className='mt-4 w-full text-sm text-gray-900 md:w-8/12'>
        {t(I18N_LOCATION + 'dialog_description')}
      </p>
      <form className='mt-2'>
        <div className='mt-6'>
          <label htmlFor='title' className='sr-only'>
            {t(I18N_LOCATION + 'input_career_title_label_sronly')}
          </label>
          <Input
            id='title'
            label={t(I18N_LOCATION + 'input_career_title_label')}
            placeholder={t(I18N_LOCATION + 'input_career_title_placeholder')}
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
            touched={formik.touched.title}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className='relative mt-6'>
          <label htmlFor='title' className='sr-only'>
            {t(I18N_LOCATION + 'input_external_custom_id_label_sronly')}
          </label>
          <Input
            id='external_custom_id'
            label={t(I18N_LOCATION + 'input_external_custom_id_label')}
            placeholder={t(
              I18N_LOCATION + 'input_external_custom_id_placeholder'
            )}
            description={t(
              I18N_LOCATION + 'input_external_custom_id_description'
            )}
            onChange={formik.handleChange}
            value={formik.values.external_custom_id}
            error={formik.errors.external_custom_id}
            touched={formik.touched.external_custom_id}
            onBlur={formik.handleBlur}
          />
          {foundCareerWithExternalCustomID && (
            <ExternalIdAlert
              className='mt-2'
              old_career_title={foundCareerWithExternalCustomID.title}
              new_career_title={formik.values.title || 'n/a'}
              external_custom_id={formik.values.external_custom_id}
            />
          )}
        </div>
        <div id='product-tour-language' className='mt-6'>
          <SelectMenu
            id='language'
            label={t(I18N_LOCATION + 'language_label')}
            options={getAllLanguages()}
            defaultValue={formik.values.language}
            onChange={(language) => formik.setFieldValue('language', language)}
          />
        </div>
        {/* <div className='mt-6'>
          <SelectMenu
            id='career_category'
            label={t(I18N_LOCATION + 'category_label')}
            options={getAllJobCategories()}
            defaultValue={formik.values.category}
            onChange={(category) => formik.setFieldValue('category', category)}
            position='auto'
          />
        </div> */}
        <div className='mt-8 flex justify-end gap-x-2 sm:mt-12'>
          <Button.SecondaryXL
            onClick={() => {
              trackEvent(trackEvents.ABORT_CREATE_CAREER)
              navigate('/careers')
            }}
            text={t('create_career.cancel_action')}
          />
          <Button.PrimaryXL
            text={
              isDuplicate
                ? t('create_career.overview.submit_action')
                : t('create_career.next_action')
            }
            onClick={() => handleSubmit(formik.values)}
            disabled={!formik.isValid}
            isLoading={formik.isSubmitting || isLoadingNext}
            className='bg-green-600 hover:bg-green-500'
          />
        </div>
      </form>
    </div>
  )
}

CreateNewCareer.propTypes = {
  career: PropTypes.object.isRequired,
  careers: PropTypes.array.isRequired,
  careerMutation: PropTypes.object.isRequired,
  isDuplicate: PropTypes.bool.isRequired
}

export default CreateNewCareer
