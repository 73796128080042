export const getCreateCareerInput = ({
  title,
  category,
  language,
  partner_id,
  status
}) => {
  return {
    title,
    category,
    language,
    status,
    partner_id,
    authorized_create: ['__create_' + partner_id],
    authorized_read: ['__read_low_' + partner_id],
    authorized_update: ['__update_' + partner_id],
    authorized_delete: ['__delete_' + partner_id],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString()
  }
}

const FIELDS_TO_COPY = [
  'app_settings',
  'career_analyse',
  'redirect_url',
  'challenge_config_id',
  'diagnostic_version'
]

export const getCopyCareerInput = ({ fromCareer }) => {
  const result = {}
  FIELDS_TO_COPY.forEach((field) => (result[field] = fromCareer[field]))

  return result
}
