import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie-player'
import { useLocation } from 'react-router'
import * as Yup from 'yup'

// import PersonHeart from '../../assets/images/illustrations/person-heart.png'
import { useQueryClient } from '@tanstack/react-query'
import EmailSuccessAnimation from '../../assets/animations/email-send-success.json'
import { DE, getAllLanguages } from '../../constants/languages'
import trackEvents from '../../constants/track-events'
import {
  useNotificationContext,
  usePartnerContext,
  usePaymentContext,
  useUserContext
} from '../../context'
import { query } from '../../graphql'
import { EMAIL_REGEX, sendSlackMessage, uuidSmall } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useMailTemplates } from '../../hooks/use-mail-templates'
import { useMutationAndUpdateQuery } from '../../hooks/use-mutation-and-update-query'
import Divider from '../divider'
import Input from '../input'
import EditMailTemplate from '../mail-template/edit-mail-template'
import SelectMenu from '../select-menu'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'
import SpaceInviteLink from './space-invite-link'

const TRANSLATION_LOCATION = 'components.career.invite_talent_modal.'

const INITIAL_VALUES = {
  email: '',
  partner_user_id: '',
  firstname: '',
  lastname: ''
}

const CreateSpaceModal = ({
  data,
  openCreateSpaceModal,
  closeCreateSpaceModal,
  open,
  invitationType,
  refetchSpaces
}) => {
  const { t } = useTranslation()
  const { is_trial } = usePaymentContext()
  const { partner } = usePartnerContext()
  const { error } = useNotificationContext()
  const {
    cognitoUser: { username }
  } = useUserContext()

  const { spaces, careers, careers_map } = data

  const { state: locationState } = useLocation()
  const queryClient = useQueryClient()

  const [state, setState] = useState('INITIAL')

  const context = invitationType

  const [uuid, setUuid] = useState()
  const [showEditMailTemplate, setShowEditMailTemplate] = useState(false)
  const [space, setSpace] = useState(null)

  const [language, setLanguage] = useState(DE)
  const [careerID, setCareerID] = useState('-1')

  useEffect(() => {
    if (context === 'EMAIL') return

    setUuid(uuidSmall())
  }, [context])

  const careerMutation = useMutationAndUpdateQuery({
    queryKey: ['talents', partner.id, username],
    mutation: 'updateCareer',
    updater: ({ prevData, response }) => {
      const newData = [...prevData]

      // queryFn: () => Promise.all([getAllSpaces(), getCareers()])
      // [0] = spaces / [1] = careers
      newData[1] = [
        ...prevData[1].filter(({ id }) => id !== response.id),
        response
      ]

      return newData
    }
  })

  const partnerMutation = useMutationAndUpdateQuery({
    queryKey: ['partner', partner.id],
    mutation: 'updatePartner'
  })

  const selectedCareer = careers_map.get(careerID)

  const { mailTemplate, mailTemplateMacros } = useMailTemplates({
    career: selectedCareer
  })

  const editMailTemplateProps = selectedCareer
    ? {
        mailTemplate,
        mailTemplateMacros,
        handleSubmit: ({ result, onSuccess, onError }) => {
          const app_settings = selectedCareer.app_settings
          app_settings.mail_template = result

          const input = { id: selectedCareer.id, app_settings }
          careerMutation.mutate({ input }, { onSuccess, onError })
        }
      }
    : {
        mailTemplate,
        mailTemplateMacros,
        handleSubmit: ({ result, onSuccess, onError }) => {
          const app_settings = partner.app_settings
          app_settings.mail_template = result

          const input = { id: partner.id, app_settings }
          partnerMutation.mutate({ input }, { onSuccess, onError })
        }
      }

  async function inviteTalent({ values, setFieldValue, resetForm }) {
    setState('IS_LOADING')

    // oneOf([
    //  'CAREER_VIA_EMAIL', 'CAREER_VIA_LINK',
    //  'TALENTPOOL_VIA_EMAIL', 'TALENTPOOL_VIA_LINK',
    // ])
    const INVITE_STATE = `${
      selectedCareer ? 'CAREER' : 'TALENTPOOL'
    }_VIA_${context}`

    if (
      (INVITE_STATE === 'CAREER_VIA_EMAIL' ||
        INVITE_STATE === 'TALENTPOOL_VIA_EMAIL') &&
      spaces.find(
        ({ person }) =>
          (person?.email || person?.invitation_email) ===
          values.email.toLowerCase()
      )
    ) {
      setState('INITIAL')

      return error(t(TRANSLATION_LOCATION + 'talent_already_invited_error'))
    }

    if (
      (INVITE_STATE === 'CAREER_VIA_LINK' ||
        INVITE_STATE === 'TALENTPOOL_VIA_LINK') &&
      spaces.find(
        ({ partner_user_id }) => partner_user_id === values.partner_user_id
      )
    ) {
      setState('INITIAL')

      return error(t(TRANSLATION_LOCATION + 'talent_already_invited_error'))
    }

    if (
      (INVITE_STATE === 'CAREER_VIA_LINK' ||
        INVITE_STATE === 'TALENTPOOL_VIA_LINK') &&
      !values.partner_user_id
    ) {
      await setFieldValue('partner_user_id', uuid)
      values.partner_user_id = uuid

      await new Promise((resolve) => setTimeout(resolve, 1000))
    }

    const input = {}

    input.send_mail = false
    input.partner_id = partner.id
    input.partner_user_id = values.partner_user_id || undefined
    input.firstname = values.firstname || undefined
    input.lastname = values.lastname || undefined

    // defaults to DE from language state
    // could be set from the career
    // or via talentpool invitation
    input.language = language

    if (
      INVITE_STATE === 'CAREER_VIA_EMAIL' ||
      INVITE_STATE === 'TALENTPOOL_VIA_EMAIL'
    ) {
      input.send_mail = true
      input.email_template = 'invite'
      input.email = values.email.toLowerCase()
    }

    if (INVITE_STATE === 'CAREER_VIA_EMAIL') {
      const { id, language: career_language } = selectedCareer

      input.career_id = id
      if (career_language) input.language = career_language
    }

    if (INVITE_STATE === 'CAREER_VIA_LINK') {
      const { id, language: career_language } = selectedCareer

      input.career_id = id
      if (career_language) input.language = career_language
    }

    try {
      const space = await query({ query: 'inviteTalent', variables: input })
      setSpace(space)
      setUuid(uuidSmall())

      if (is_trial) {
        await queryClient.refetchQueries({
          queryKey: ['trial-data', partner.id],
          type: 'active'
        })
      }

      const viaEmail = INVITE_STATE.includes('EMAIL')
      const viaLink = INVITE_STATE.includes('LINK')

      if (INVITE_STATE.includes('CAREER')) {
        trackEvent(trackEvents.INVITE_TALENT_CAREER, {
          type: viaEmail ? 'email' : 'link'
        })

        sendSlackMessage(
          'kunden-controlling',
          `${partner.display_name} hat soeben einen Talent zur Stelle ${
            selectedCareer.title
          } eingeladen (${viaEmail ? 'via E-Mail' : 'via Link'}).`
        )
      }

      if (INVITE_STATE.includes('TALENTPOOL')) {
        trackEvent(trackEvents.INVITE_TALENT_POOL, {
          type: viaEmail ? 'email' : 'link'
        })

        sendSlackMessage(
          'kunden-controlling',
          `${
            partner.display_name
          } hat soeben einen Talent ohne Stellenbezug eingeladen (${
            viaEmail ? 'via E-Mail' : 'via Link'
          }).`
        )
      }

      if (!spaces.length) {
        // a new create-space-modal gets rendered after refetch spaces
        refetchSpaces()
        handleClose({})

        return
      }

      refetchSpaces()

      if (viaEmail) setState('SUCCESS_VIA_EMAIL')
      if (viaLink) setState('SUCCESS_VIA_LINK')

      resetForm()
    } catch (err) {
      if (err.message === 'NOT_AUTHORIZED') {
        setState('INITIAL')
        return
      }

      if (INVITE_STATE === 'TALENTPOOL_VIA_EMAIL') {
        error(t(TRANSLATION_LOCATION + 'invite_talent_email_error'))
      }

      if (INVITE_STATE === 'CAREER_VIA_EMAIL') {
        error(t(TRANSLATION_LOCATION + 'invite_talent_link_error'))
      }

      setState('INITIAL')
    }
  }

  const handleClose = ({ skipSetState = false }) => {
    closeCreateSpaceModal()

    !skipSetState && setTimeout(() => setState('INITIAL'), 1000 * 0.8)
  }

  const isFirstnameRequired = mailTemplate.has_firstname_macro
  const isLastnameRequired = mailTemplate.has_lastname_macro

  return (
    <>
      <Modal open={open} setOpen={() => closeCreateSpaceModal()} size='lg'>
        <h2 className='text-3xl font-extrabold tracking-tight text-gray-900'>
          {invitationType === 'EMAIL'
            ? t(TRANSLATION_LOCATION + 'modal_title_viaemail')
            : t(TRANSLATION_LOCATION + 'modal_title_vialink')}
        </h2>
        {['INITIAL', 'IS_LOADING'].includes(state) && (
          <div className='mt-4'>
            <div className='mt-6 max-w-lg'>
              <SelectMenu
                id='career_id'
                label={t(TRANSLATION_LOCATION + 'select_career_dropdown_label')}
                options={[
                  ['-1', t('talents.talentpool_title')],
                  ...careers
                    .filter(({ archived }) => !archived)
                    .filter(({ id }) => !id.includes('example_'))
                    .map(({ id, title, external_custom_id }) => [
                      id,
                      title,
                      external_custom_id && `| ${external_custom_id}`
                    ])
                ]}
                onChange={setCareerID}
                defaultValue={careerID}
                translate={false}
              />
            </div>
            {context === 'EMAIL' && (
              <div className='mt-6 flex items-center justify-between'>
                <div>
                  <span className='block text-sm font-medium text-gray-700'>
                    {t(TRANSLATION_LOCATION + 'mail_template_dropdown_label')}
                  </span>
                  <span className='mt-1 block w-64 text-sm font-semibold text-gray-900'>
                    {mailTemplate.subject}
                  </span>
                  {!selectedCareer && false && (
                    <SelectMenu
                      id='language'
                      label={t(
                        TRANSLATION_LOCATION + 'language_dropdown_label'
                      )}
                      options={getAllLanguages()}
                      onChange={setLanguage}
                      defaultValue={language}
                      className='mt-6 sm:col-span-2 sm:mt-0'
                    />
                  )}
                </div>
                <Button.SoftSM
                  text={t(TRANSLATION_LOCATION + 'edit_mail_template_action')}
                  onClick={() => {
                    handleClose({ skipSetState: true })
                    setTimeout(() => setShowEditMailTemplate(true), 1000 * 0.4)
                  }}
                />
              </div>
            )}
            <div className='px-4'>
              <Divider />
            </div>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{
                ...INITIAL_VALUES,
                ...(locationState?.inviteYourself || {})
              }}
              onSubmit={async (
                values,
                { setFieldValue, resetForm, setFieldError }
              ) => {
                if (
                  spaces.some(
                    (s) => s.identification === values.partner_user_id
                  )
                ) {
                  setFieldError(
                    'partner_user_id',
                    `${values.partner_user_id} als eindeutiger Bezeichner bereits in Verwendung`
                  )
                  return
                }

                await inviteTalent({ values, setFieldValue, resetForm })
              }}
              validationSchema={
                context === 'EMAIL'
                  ? Yup.object({
                      career: Yup.string(),
                      email: Yup.string()
                        .matches(
                          EMAIL_REGEX,
                          t(TRANSLATION_LOCATION + 'validation_email_error')
                        )
                        .required(
                          t(TRANSLATION_LOCATION + 'validation_required')
                        ),
                      partner_user_id: Yup.string().matches(
                        /^[a-zA-Z0-9-]*$/gm,
                        t(
                          'components.career.invite_talent_modal.partner_user_id_validation_error'
                        )
                      ),
                      lastname: isLastnameRequired
                        ? Yup.string().required(
                            t(TRANSLATION_LOCATION + 'validation_required')
                          )
                        : Yup.string(),
                      firstname: isFirstnameRequired
                        ? Yup.string().required(
                            t(TRANSLATION_LOCATION + 'validation_required')
                          )
                        : Yup.string(),
                      language: Yup.string(),
                      talentpoolMailTemplate: Yup.number()
                    })
                  : Yup.object({
                      career: Yup.string(),
                      partner_user_id: Yup.string().matches(
                        /^[a-zA-Z0-9-]*$/gm,
                        t(
                          'components.career.invite_talent_modal.partner_user_id_validation_error'
                        )
                      ),
                      language: Yup.string()
                    })
              }
            >
              {({
                values,
                handleChange,
                handleBlur,
                touched,
                errors,
                isValid,
                handleSubmit
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className='mt-6 flex flex-col gap-y-6 lg:grid lg:grid-cols-2 lg:gap-x-4'>
                    {[
                      {
                        id: 'email',
                        label: t(TRANSLATION_LOCATION + 'email_label'),
                        description: undefined,
                        visible: context === 'EMAIL',
                        className: 'lg:col-span-2'
                      },
                      {
                        id: 'partner_user_id',
                        label: t(
                          TRANSLATION_LOCATION + 'partner_user_id_label'
                        ),
                        optional: true,
                        description: t(
                          TRANSLATION_LOCATION + 'partner_user_id_description'
                        ),
                        className: 'lg:col-span-2',
                        inlineErrorMessage: false,
                        visible: true // show this input field always
                      },
                      {
                        id: 'firstname',
                        className: 'lg:col-span-1',
                        label: t(TRANSLATION_LOCATION + 'firstname_label'),
                        optional: !isFirstnameRequired,
                        visible: true // show this input field always
                      },
                      {
                        id: 'lastname',
                        label: t(TRANSLATION_LOCATION + 'lastname_label'),
                        optional: !isLastnameRequired,
                        className: 'lg:col-span-1',
                        visible: true // show this input field always
                      }
                    ]
                      .map((input) => ({
                        ...input,
                        value: values[input.id],
                        onChange: async (event) => {
                          if (event.target.id === 'email') {
                            event.target.value =
                              event.target.value.toLowerCase()
                          }
                          handleChange(event)
                        },
                        onBlur: handleBlur,
                        touched: touched[input.id],
                        error: errors[input.id]
                      }))
                      .filter(({ visible }) => visible)
                      .map((input, index) => (
                        <div key={index} className={input.className}>
                          <Input
                            {...input}
                            type='text'
                            disabled={state === 'IS_LOADING'}
                          />
                        </div>
                      ))}
                  </div>
                  <div className='mt-8 flex'>
                    <Button.PrimaryXL
                      fullWidth
                      isLoading={state === 'IS_LOADING'}
                      disabled={context === 'EMAIL' && !isValid}
                      text={t(
                        TRANSLATION_LOCATION +
                          (context === 'EMAIL'
                            ? 'invite_action'
                            : 'invite_action_link')
                      )}
                      onClick={handleSubmit}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {['SUCCESS_VIA_EMAIL', 'SUCCESS_VIA_LINK'].includes(state) && (
          <div className='mt-4'>
            <div className='flex items-center justify-between'>
              <span className='text-md block text-gray-900'>
                {state === 'SUCCESS_VIA_EMAIL' &&
                  t(TRANSLATION_LOCATION + 'success_via_email')}
                {state === 'SUCCESS_VIA_LINK' &&
                  t(TRANSLATION_LOCATION + 'success_via_link')}
              </span>

              {/* <img
                className='hidden sm:block w-32 h-auto'
                alt='Person Heart'
                src={PersonHeart}
              /> */}
            </div>
            {['SUCCESS_VIA_EMAIL'].includes(state) && (
              <div className='flex w-full justify-center'>
                <Lottie
                  animationData={EmailSuccessAnimation}
                  play
                  loop={false}
                  style={{ width: 150, height: 150 }}
                />
              </div>
            )}
            {state === 'SUCCESS_VIA_LINK' && (
              <div className='mt-8'>
                <SpaceInviteLink spaceID={space.id} language={space.language} />
              </div>
            )}
            <div className='mt-12 flex gap-x-4'>
              <Button.SecondaryXL
                fullWidth
                onClick={() => handleClose({})}
                text={t(TRANSLATION_LOCATION + 'done_action')}
              />
              <Button.PrimaryXL
                fullWidth
                text={t(TRANSLATION_LOCATION + 'create_new_space_action')}
                onClick={() => setState('INITIAL')}
              />
            </div>
          </div>
        )}
      </Modal>
      <EditMailTemplate
        open={showEditMailTemplate}
        setOpen={() => {
          setShowEditMailTemplate(false)
          setTimeout(() => openCreateSpaceModal(), 1000 * 0.4)
        }}
        {...editMailTemplateProps}
      />
    </>
  )
}

CreateSpaceModal.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeCreateSpaceModal: PropTypes.func.isRequired,
  openCreateSpaceModal: PropTypes.func.isRequired,
  refetchSpaces: PropTypes.func.isRequired,
  invitationType: PropTypes.string
}

CreateSpaceModal.defaultProps = {
  invitationType: null
}

export default CreateSpaceModal
