import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from '..'
import trackEvents from '../../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext,
  useProductTourContext
} from '../../context'
import { query } from '../../graphql'
import { notifyBugsnag, uuidSmall } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useCreateCareerNavigation } from '../../hooks/use-create-career-navigation'
import { useMount } from '../../hooks/use-mount'
import Button from '../tailwind/Button'
import Analyses from './career-analyse/analyses'
import ModalInviteExperts from './career-analyse/modal-invite-experts'
import ModalSelectCareer from './career-analyse/modal-select-career'
import ModalSelectPreset from './career-analyse/modal-select-preset'

const TRANSLATION_LOCATION = 'components.career.expert_analysis.'

const CareerAnalyse = ({ career, careerMutation }) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()
  const { success, error, alert } = useNotificationContext()

  const [uuid, setUuid] = useState(uuidSmall())
  const [openModal, setOpenModal] = useState(null)
  const [isLoadingSynthesizeAnalyze, setIsLoadingSynthesizeAnalyze] =
    useState(false)

  const {
    setProductTourState,
    productTourState: { tourActive }
  } = useProductTourContext()

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setProductTourState({ run: true })
      }, 0.4 * 1000)
    }
  })

  const {
    data: careerAnalyses,
    status: careerAnalysesStatus,
    refetch: refetchCareerAnalyses
  } = useQuery({
    queryKey: ['career-analyses', career.id],
    queryFn: () =>
      query({
        query: 'careerAnalyseByCareer',
        variables: { career_id: career.id }
      }),
    select: (data) =>
      data.map((analyse) => ({
        ...analyse,
        preset: analyse.preset ? JSON.parse(analyse.preset) : null
      }))
  })

  const { data: careers, status: careersStatus } = useQuery({
    queryKey: ['careers-with-analyses', partner.id],
    queryFn: async () => {
      const careers_response = await query({
        query: 'careerByPartnerByDate',
        variables: { partner_id: partner.id }
      })

      const active_careers_v2 = careers_response.filter(
        ({ status, archived, diagnostic_version }) =>
          status === 'ACTIVE' && !archived && diagnostic_version === 2
      )

      for (const career of active_careers_v2) {
        const analyses = await query({
          query: 'careerAnalyseByCareer',
          variables: { career_id: career.id }
        })

        career.analyses = analyses
      }

      const careers_with_analyses = active_careers_v2.filter(
        ({ analyses }) => !!analyses.length
      )

      return careers_with_analyses
    }
  })

  const {
    isLoadingBack,
    isLoadingNext,
    handleBackNavigation,
    handleNextNavigation
  } = useCreateCareerNavigation({ career, careerMutation })

  const generateRequirementProfile = async () => {
    const pdfDocument = window.open('', '_blank')
    pdfDocument.document.write(
      t(TRANSLATION_LOCATION + 'requirement_profile_preparation')
    )

    try {
      await query({
        query: 'synthesizeAnalyze',
        variables: { career_id: career.id }
      })

      const pdfResponse = await query({
        query: 'generatePdf',
        variables: {
          career_id: career.id,
          language,
          type: 'REQUIREMENTPROFILE'
        }
      })

      pdfDocument.location.href = JSON.parse(pdfResponse).headers.Location
      success(t(TRANSLATION_LOCATION + 'requirement_profile_success'))
    } catch (err) {
      error(t(TRANSLATION_LOCATION + 'requirement_profile_error'))
      notifyBugsnag(err)
      pdfDocument.close()
    }
  }

  const showNotAllAnalysesCompletedHint = () => {
    alert(
      t(TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_title'),
      t(TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_text'),
      [
        {
          onPress: () => {},
          text: t(
            TRANSLATION_LOCATION + 'modal_not_all_analyses_completed_action'
          )
        }
      ]
    )
  }

  if (careerAnalysesStatus === 'pending' || careersStatus === 'pending') {
    return <ActivityIndicator />
  }

  const careerAnalysesVerifiedCount = careerAnalyses.filter(
    ({ status }) => status === 'COMPLETED'
  ).length

  return (
    <>
      <div className='mx-auto max-w-4xl'>
        <div className='mx-auto py-6'>
          <div className='relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0'>
            <svg
              viewBox='0 0 1024 1024'
              aria-hidden='true'
              className='absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0'
            >
              <defs>
                <radialGradient id='759c1415-0410-454c-8f7c-9a820de03641'>
                  <stop stopColor='#7775D6' />
                  <stop offset={1} stopColor='#E935C1' />
                </radialGradient>
              </defs>
            </svg>
            <div className='mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left'>
              <h2 className='text-balance text-3xl tracking-tight text-white sm:text-4xl'>
                {t('create_career.career_analyse.title') + ' '}
                <span className='font-bold'>
                  {t('create_career.career_analyse.title_span_1')}
                </span>
              </h2>
              <p className='mt-6 text-pretty text-lg/8 text-gray-300'>
                {t('create_career.career_analyse.text_1')}{' '}
                <span className='font-bold' style={{ color: '#4164dd' }}>
                  {t('create_career.career_analyse.text_2')}
                </span>{' '}
                {t('create_career.career_analyse.text_3')}{' '}
                <span className='font-bold' style={{ color: '#4164dd' }}>
                  {t('create_career.career_analyse.text_4')}
                </span>
                {t('create_career.career_analyse.text_5')}
              </p>
            </div>
            <div className='relative mt-16 h-80 lg:mt-8'>
              <img
                alt='App screenshot'
                src='https://aivy-assets.s3.eu-central-1.amazonaws.com/dashboard/profile.png'
                width={900}
                height={500}
                className='absolute left-0 top-0 w-[48rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10'
              />
            </div>
          </div>
        </div>
        {/* <p className='mt-4 max-w-xl text-sm/5 text-gray-900'>
          {t('create_career.career_analyse.description')}
        </p> */}
        <Analyses
          {...{
            career,
            careers,
            careerAnalyses,
            refetchCareerAnalyses,
            setOpenModal
          }}
        />
        <div className='mt-8 flex flex-row items-center justify-between sm:mt-16'>
          <Button.SecondaryXL
            onClick={() => handleBackNavigation({})}
            isLoading={isLoadingBack}
            text={t('create_career.back_action')}
          />
          <div className='flex gap-x-2'>
            <Button.SecondaryXL
              className='mr-auto'
              text={t(TRANSLATION_LOCATION + 'requirement_profile_action')}
              onClick={generateRequirementProfile}
              disabled={careerAnalysesVerifiedCount === 0}
            />
            <Button.PrimaryXL
              text={t('create_career.next_action')}
              className='bg-green-600 hover:bg-green-500'
              onClick={async () => {
                try {
                  if (careerAnalyses.length !== careerAnalysesVerifiedCount) {
                    showNotAllAnalysesCompletedHint()
                    return
                  }
                  setIsLoadingSynthesizeAnalyze(true)
                  await query({
                    query: 'synthesizeAnalyze',
                    variables: { career_id: career.id }
                  })
                  setIsLoadingSynthesizeAnalyze(false)

                  handleNextNavigation({
                    // career_analyse is calculated by summarize || select preset
                    onSuccess: () => {
                      trackEvent(trackEvents.FINISH_CAREER_ANALYSE_BY_EXPERT)
                    }
                  })
                } catch (err) {
                  setIsLoadingSynthesizeAnalyze(false)
                  notifyBugsnag(err)
                }
              }}
              isLoading={isLoadingSynthesizeAnalyze || isLoadingNext}
              disabled={careerAnalysesVerifiedCount < 1}
            />
          </div>
        </div>
      </div>
      <ModalInviteExperts
        key={uuid}
        {...{
          career,
          careerAnalyses,
          refetchCareerAnalyses,
          open: openModal === 'invite-experts',
          closeModal: () => {
            setOpenModal(null)
            // reset key of invite experts modal to render it again
            setTimeout(() => setUuid(uuidSmall()), 0.6 * 1000)
          }
        }}
      />
      <ModalSelectPreset
        {...{
          career,
          refetchCareerAnalyses,
          open: openModal === 'select-preset',
          closeModal: () => setOpenModal(null)
        }}
      />
      <ModalSelectCareer
        {...{
          career,
          careers,
          refetchCareerAnalyses,
          open: openModal === 'select-career',
          closeModal: () => setOpenModal(null)
        }}
      />
    </>
  )
}

CareerAnalyse.propTypes = {
  career: PropTypes.object.isRequired,
  careerMutation: PropTypes.object.isRequired
}

export default CareerAnalyse
